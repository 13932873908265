@use "../styles/theme.scss";

:local(.home-page) {
  flex: 1;
  background-size: cover;
  padding: 0;
  flex-direction: column;
  align-items: center;

  @media (min-width: theme.$breakpoint-lg) {
    align-items: center;
    justify-content: flex-start;
  }

  & > section {
    margin: 2em auto;
  }
}

:local(.logo-container) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  width: 250px;
  max-width: 100%;
  margin-top: -35px;
  
  img {
    width: 100%;
    align-self: flex-start;
  }

  @media (min-width: theme.$breakpoint-lg) {
    display: none;
  }
}

:local(.sign-in-container) {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-top: -52px;
  @media(min-width: theme.$breakpoint-lg) {
    display: none;
  }
}

:local(.mobile-sign-out) {
  padding: 0.5rem;
}

:local(.hero) {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (min-width: theme.$breakpoint-lg) {
    flex-direction: row;
  }
}

:local(.hero-image-container) {

  img {
    @media (min-width: theme.$breakpoint-lg) {
      border-radius: 16px;
    }
  }
}

:local(.app-info) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 40px 20px;

  & > * {
    margin-bottom: 20px;
  }

  h1 {
    font-size: 50px;
    line-height: 115%;
    margin-bottom: 24px;
    text-align: center;
  }
  h1 span {
    color: theme.$blue;
  }
  
  p {
    font-size: 24px;
    line-height: 150%;
    max-width: 484px;
    margin-bottom: 14px;
  }

  p.highlight {
    color: theme.$blue;
  }

  @media (min-width: theme.$breakpoint-lg) {
    margin-bottom: 0;
    margin-left: 16px;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 100px 0;

    & > :last-child {
      margin-bottom: 0;
    }

    h1 {
      text-align: left;
    }
  }
}

:local(.app-description) {
  white-space: pre-wrap;
  align-self: auto;
  font-size: theme.$font-size-lg;
  font-weight: theme.$font-weight-medium;
  text-align: center;
  margin: 0 24px 20px 24px;
  line-height: 1.25;

  @media (min-width: theme.$breakpoint-lg) {
    text-align: left;
    margin: 0 16px 48px 0px;
    max-width: 860px;
    font-size: theme.$font-size-2xl;
  }
}

:local(.center-logo) {
  align-self: auto;
  margin: 24px 0;
}

:local(.cta-buttons) {
  display: flex;
  flex-direction: column;
  align-items: center;
}

:local(.features) {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  
  & > * {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    @media(min-width: theme.$breakpoint-lg) {
      margin-right: 24px;
      margin-bottom: 0;
      flex-direction: row;
      max-width: 364px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  
  img {
    width: 100%;
    border-radius: 12px;
  }
}

:local(.rooms-container) {
  margin-top: 100px !important;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  @media(min-width: theme.$breakpoint-lg) {
    margin-top: 120px !important;
    padding: 0;
  }
}

:local(.rooms-heading) {  
  font-size: 36px;
  margin-bottom: 16px;
  text-align: center;
  line-height: 115%;
  @media(min-width: theme.$breakpoint-lg) {
    margin-left: 40px;
    text-align: left;
  }
}
 
:local(.rooms) {
  background-color: theme.$background2-color;
  border-radius: 16px;
}

:local(.featured-room) {
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  display: flex !important;
  flex-direction: column-reverse !important;

  p {
    font-weight: 500;
    line-height: 150%;
  }
  @media(min-width: theme.$breakpoint-lg) {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(420px, 1fr)) !important;
    p {
      padding: 50px 60px;
    }
  }
}

:local(.row) {
  display: flex;
  justify-content: space-evenly !important;
}

:local(.col-lg) {
  flex-direction: column;

  @media(min-width: theme.$breakpoint-lg) {
    flex-direction: row;
  }
}

:local(.home-page) :local(.card) {
  background-color: theme.$background2-color;
  border-radius: 16px;
  font-weight: theme.$font-weight-medium;
  padding-bottom: 32px !important;
  flex: 1;
}

:local(.center-lg) {
  @media(max-width: theme.$breakpoint-lg) {
    text-align: center;
    align-items: center;
  }
}

:local(.news-item) {  

  text-align: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 0;

  p {
    margin-top:10px;
    margin-bottom:10px;
  }
}

:local(.news-item):last-child {  
  border-bottom: none;
}

:local(.rarity) {
  margin: 6em auto !important;
  display: flex !important;
  flex-direction: column-reverse !important;
  padding: 0 20px;
  max-width: 1140px;

  > div {
    flex: 1;
  }

  > div:first-child {
    padding: 1em 0px;
  }
  
  h2 {
    font-size: 36px;
    line-height: 115%;
    text-align: center;
  }
  img {
    max-width: 70%;
  }

  p {
    font-size: 18px;
    line-height: 150%;
  }

  @media(min-width: theme.$breakpoint-lg) {
    padding: 0;
    margin: 10em auto  !important;
    flex-direction: row  !important;

    > div:first-child {
      padding: 11em 0px;
    }

    h2 {
      text-align: left;
    }
    img {
      max-width: 100%;
    }
  }
}

:local(.tutorial) {
  background-color: #262626;
  color: #c4c4c4;
  padding: 0 20px;  

  > section {
    padding: 6em 0;
    max-width: 1140px;
  }
  h2 {
    font-size: 36px;
    line-height: 115%;
  }
  ul {
    list-style: disc;
    margin-left: 16px;
    line-height: 150%;
  }
}

:local(.roadmap-container) {
  max-width: 100%;
  padding: 6em 20px;
  h2 {    
    font-size: 36px;
    line-height: 115%;
    text-align: center;
  }
  @media(min-width: theme.$breakpoint-lg) {
    max-width: 560px;
    padding: 11em 0;
    margin: 0 auto;
    h2 {
      text-align: left;
    }
  }
}

:local(.faq) {
  padding: 0 20px;

  > div {
    max-width: 100%; 
    margin: 4em auto;
  }
  h2 {
    font-size: 36px; 
    line-height: 115%;
  }
  h5 {
    font-size: 24px; 
    font-weight: 600;
  }
  @media(min-width: theme.$breakpoint-lg) {
    padding: 0;
    > div {
      max-width: 560px; 
      margin: 8em auto;
    }
  }
}

.roadmap {
  background: rgba(226, 245, 255, 0.5);
  position: relative;

  &__container {
    max-width: 586px;
    margin: auto;
    position: relative;
    z-index: 10;
    padding: 200px 0 159px;   
  }

  &__title {
    font-size: 36px;
    line-height: 115%;
    margin-bottom: 40px;
  }

  &__item {
    width: auto;
    padding-bottom: 25px;
    padding-left: 41px;
    position: relative;
    max-width: 89vw;

    &::after {
      content: "";
      width: 20px;
      height: 20px;
      background-color: #579ff4;
      border: 4px solid #fff;
      border-radius: 50%;
      position: absolute;
      left: 3px;
      top: 9px;
    }

    &:not(:last-child)::before {
      position: absolute;
      display: block;
      height: 95%;
      content: '';
      left: 12px;
      bottom: 0;
      border-left: 3px dashed rgba(87, 159, 244, 0.5);
    }

    &-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
      margin-bottom: 15px;
    }

    &-text {
      line-height: 21px;
      margin-bottom: 10px;
    }
  }

  &__icon {
    position: absolute;
    right: -57px;
    bottom: -50px;
    transform: translateX(100%);
  
    svg {
      width: 274px;
      height: fit-content;

    }
  }

  &__background {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 5;

    &-ellipse-left {
      position: absolute;
      left: 0;
    }

    &-ellipse-right {
      position: absolute;
      left: 50%;
      transform: translate(-100px, -100px);
      top: 127px;
    }

    &-clouds {
      position: absolute;
      top: -150px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 5;

      svg {
        width: 1600px;
      }
    }
  }
}
